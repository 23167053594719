<template>

      <div class="container-fluid px-0">
        <div class="container-fluid">
          <div class="bg-holder bg-dark" ></div>
         
          <div class="row flex-center position-relative min-vh-100 g-0 py-5">
            <div class="col-11 col-sm-10 col-xl-4">
              <div class="card border border-300 auth-card">
                <div class="card-body">
                  <div class="row align-items-center gx-0 gy-7">
                    <div class="col mx-auto">
                      <form @submit.prevent="validar()">
                        <div class="auth-form-box">
                          <div class="text-center ">
                              <a class="d-flex flex-center text-decoration-none mb-4" href="/">
                                <div class="d-flex align-items-center fw-bolder fs-5 d-inline-block">
                                  <img src="@/assets/img/logo.png" alt="IASC" width="208" />
                              </div>
                            </a>
                            <h3 class="text-1000 p-2">PORTAL IASC</h3>
                          </div>
                          <div class="alert alert-danger" v-if="msg_err" role="alert">
                            {{msg_err}}
                          </div>
                          <div class="mb-3 text-start">
                            <label class="form-label" for="email">Email</label>
                            <div class="form-icon-container">
                              <input class="form-control form-icon-input" id="email" v-model="email" type="email" placeholder="nombre@ejempl.com" /><span class="fas fa-user text-900 fs--1 form-icon"></span>
                            </div>
                          </div>
                          <div class="mb-3 text-start">
                            <label class="form-label" for="password">Contraseña</label>
                            <div class="form-icon-container">
                              <input class="form-control form-icon-input" id="password" v-model="password" type="password" placeholder="Contraseña" /><span class="fas fa-key text-900 fs--1 form-icon"></span>
                            </div>
                          </div>
                          <div class="row flex-between-center mb-7">
                            <div class="col-auto">
                              <div class="form-check mb-0">
                                <input class="form-check-input" id="basic-checkbox" v-model="recuerda_token" type="checkbox" checked="checked" />
                                <label class="form-check-label mb-0" for="basic-checkbox">Recordar mi sesión</label>
                              </div>
                            </div>
                          </div>
                          <button type="submit" class="btn btn-primary w-100 mb-3">Iniciar Sesión</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
             
  </template>
  
  <script>
  // @ is an alias to /src
  import axios from 'axios'
  export default {
    name: 'LoginView',
    data(){
        return{
            email: '',
            password: '',
            msg_err: '',
            recuerda_token: true,
            show:false
        }
    },
    methods:{
        validar(){
            if(!this.email){
                this.msg_err = 'ingrese un correo'
            }else if(!this.password){
                this.msg_err = 'ingrese una contraseña'
            }else{
                this.msg_err = ''
                this.login()
            }
        },
        login(){
          let data = {
              email: this.email,
              password: this.password,
              recuerda_token: this.recuerda_token
          }

         axios.post(this.$url+'/login',data,{
             headers: {
                 'Content-Type' : 'application/json'
             }
         }).then((res)=>{
             if(res.data.message){
                 this.msg_err = res.data.message
             }else{
               if(res.data.token){
                 this.msg_err= ''
                 this.$store.dispatch('saveToken',res.data.token)
                 this.$router.push({name: 'Tablero'})
             }
           }
         }).catch((err)=>{
             this.msg_err= err.response.data.message
         })
        }
    }
  }
  </script>
  
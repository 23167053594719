<template>
     <section>
        <SideBar />
        <TopBar />
        <div class="content">   
            <div class="app-content content">
                <div class="content-overlay"></div>
                <div class="content-wrapper">
                    <div class="content-header row">
                        <div class="content-header-left col-md-8 col-12 mb-2">
                            <h3 class="content-header-title mb-0">Poliza de Cliente {{ cliente.ClientesID | leadingZeros(5) }}</h3>
                        </div>
                        <div class="content-header-right text-md-end col-md-2 col-12">
                            <div class="form-group">
                                <button v-if="mode == 'edit'" type="button" @click="desactivarCampos = !desactivarCampos" class="btn-sm btn btn-secondary" >Editar</button>
                            </div> 
                        </div>
                        <div class="content-header-right text-md-end col-md-2 col-12">
                            <div class="form-group">
                                <router-link to="/polizas" class="btn-sm btn btn-secondary" >Regresar</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="content-body">
                        <!-- users edit start -->
                        <section class="users-edit">
                            <div class="card">
                                <div class="card-content">
                                    <div class="card-body">
                                        
                                        <div class="tab-content">
                                            <div class="tab-pane active" id="account" aria-labelledby="account-tab" role="tabpanel">
                                                <form @submit.prevent="setCliente()">
                                                    <div class="row">
                                                        <div class="col-12 col-sm-8 border-end">
                                                            <div class="row">
                                                                <div class="col-12 col-sm-8">
                                                                    <div class="form-group">
                                                                        <label>Empresa</label>
                                                                        <input type="text" class="form-control" v-model="cliente.Nombre" :disabled="desactivarCampos">
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-sm-4">
                                                                    <div class="form-group">
                                                                        <div class="controls">
                                                                            <label>RFC</label>
                                                                            <input type="text" class="form-control" v-model="cliente.RFC" :disabled="desactivarCampos">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            
                                                                
                                                                <div class="col-12 col-sm-4">
                                                                    <div class="form-group">
                                                                        <label>Teléfono</label>
                                                                        <input type="text" class="form-control" v-model="cliente.Telefono"  :disabled="desactivarCampos">
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-sm-4">
                                                                    <div class="form-group">
                                                                        <label>Contacto</label>
                                                                        <input type="text" class="form-control"  v-model="cliente.Contacto" :disabled="desactivarCampos">
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-sm-4">
                                                                    <div class="form-group">
                                                                        <label>Correo</label>
                                                                        <input type="email" class="form-control"  v-model="cliente.Correo" :disabled="desactivarCampos">
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 ">
                                                                    <div class="form-group">
                                                                        <label>Comentarios</label>
                                                                        <textarea class="form-control" rows="5" v-model="cliente.Comentarios" ></textarea>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 ">
                                                                    <div class="form-group">
                                                                        <label><h6>Empresas Relacionadas</h6></label>
                                                                        <textarea class="form-control" rows="5" v-model="cliente.Empresas_relacionadas"></textarea>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4">
                                                            <div class="col-12 col-sm-6 ">
                                                                <div class="form-group">
                                                                    <label>Fecha Exp. Actualizaciones</label>
                                                                    <input type="date" class="form-control text-danger" v-model="cliente.ExpActualizaciones"  >
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-sm-12 " v-if="cliente.poliza" >
                                                                <div class="card border p-3">
                                                                    <h6>Factura</h6>
                                                                    <div class="row">
                                                                        <div class="col-6">
                                                                            <div class="form-group">
                                                                                <label>Folio</label>
                                                                                <input type="number" class="form-control" v-model="cliente.poliza.POL_FACTURA"  >
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col-6 ">
                                                                            <div class="form-group">
                                                                                <label>Fecha Factura</label>
                                                                                <input type="date" class="form-control" v-model="cliente.poliza.POL_FECHA_FAC"  >
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6">
                                                                            <div class="form-group">
                                                                                <label>Fecha Pago</label>
                                                                                <input type="date" class="form-control" v-model="cliente.poliza.POL_FECHA_PAGO"  >
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <h6>Periodo Factura</h6>
                                                                    <div class="row">
                                                                        <div class="col-6">
                                                                            <div class="form-group">
                                                                                <label>Fecha Ini.</label>
                                                                                <input type="date" class="form-control" v-model="cliente.poliza.POL_FECHA_INI"  >
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6">
                                                                            <div class="form-group">
                                                                                <label>Fecha Fin.</label>
                                                                                <input type="date" class="form-control" v-model="cliente.poliza.POL_FECHA_FIN"  >
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-12 ">
                                                                        <div class="form-group">
                                                                            <label><h6>Sistemas Contratados</h6></label>
                                                                            <div class="row">
                                                                                <div class="col-6">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input"  v-model="cliente.poliza.POL_FUSSION "  type="checkbox" id="fussion">
                                                                                        <label class="form-check-label" for="fussion">Fussion</label>
                                                                                    </div>
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input"  v-model="cliente.poliza.POL_FACTURACION "  type="checkbox" id="fact">
                                                                                        <label class="form-check-label" for="fact">Facturación</label>
                                                                                    </div>
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input"  v-model="cliente.poliza.POL_MANTENIMIENTO "  type="checkbox" id="mtto">
                                                                                        <label class="form-check-label" for="mtto">Mantenimiento</label>
                                                                                    </div>
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input"  v-model="cliente.poliza.POL_NOMINAS "  type="checkbox" id="nominas">
                                                                                        <label class="form-check-label" for="nominas">Nóminas</label>
                                                                                    </div>
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input"  v-model="cliente.poliza.POL_CONTABILIDAD "  type="checkbox" id="conta">
                                                                                        <label class="form-check-label" for="conta">Contabilidad</label>
                                                                                    </div>
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input"  v-model="cliente.poliza.POL_INVENTARIOSCOMPRAS "  type="checkbox" id="inco">
                                                                                        <label class="form-check-label" for="inco">Inventarios y Compras</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-6">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input"  v-model="cliente.poliza.POL_CONSTRUCCION "  type="checkbox" id="construccion">
                                                                                        <label class="form-check-label" for="construccion">Construcción</label>
                                                                                    </div>
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input"  v-model="cliente.poliza.POL_WEB"  type="checkbox" id="web">
                                                                                        <label class="form-check-label" for="web">Web</label>
                                                                                    </div>
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input"  v-model="cliente.poliza.POL_RESPALDOS "  type="checkbox" id="respaldos">
                                                                                        <label class="form-check-label" for="respaldos">Respaldos</label>
                                                                                    </div>
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input"  v-model="cliente.poliza.POL_METADATA "  type="checkbox" id="metadata">
                                                                                        <label class="form-check-label" for="metadata">Metadata</label>
                                                                                    </div>
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input"  v-model="cliente.poliza.POL_APP "  type="checkbox" id="app">
                                                                                        <label class="form-check-label" for="app">App</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 ">
                                                                        <div class="form-group">
                                                                            <label><h6>Servicio de Timbrado</h6></label>
                                                                            <div class="row">
                                                                                <div class="col-6">
                                                                                    <div class="row">
                                                                                        <label class="col-3 text-nowrap text-right">PAC</label>
                                                                                        <div class="col-9">
                                                                                            <select class="form-control form-control-sm" v-model="cliente.timbrado.PAC">
                                                                                                <option value="pgia">Prodigia (pgia)</option>
                                                                                                <option value="codi">Comercio Digital (codi)</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-6">
                                                                                    <div class="row">
                                                                                        <label class="col-3 text-nowrap text-right">Nóminas</label>
                                                                                        <div class="col-9">
                                                                                            <select class="form-control form-control-sm" v-model="cliente.timbrado.NOMINA">
                                                                                                <option value="pgia">Prodigia (pgia)</option>
                                                                                                <option value="codi">Comercio Digital (codi)</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>                                                                        
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 d-flex  pt-4 flex-sm-row flex-column justify-content-end mt-1">
                                                            <button type="submit" class="me-2 btn btn-primary btn-sm glow mb-1 mb-sm-0 mr-0 mr-sm-1">
                                                            {{ mode != 'edit' ? 'Agregar Cliente' : 'Guardar Cambios' }}
                                                            </button>
                                                            <router-link to="/polizas" class="btn btn-sm btn-outline-danger">Salir</router-link>
                                                        </div>
                                                    </div>
                                                </form>
                                                <!-- users edit account form ends -->
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <!-- users edit ends -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
import axios from 'axios'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
export default {
    name: 'LicenciasClientesFormComponent',
    components:{SideBar,TopBar,LoadingSpinner},
    data(){
        return{
            cliente:{},
            poliza:{},
            mode: this.$route.params.id ? 'edit' : 'add',
            loading:false,
            desactivarCampos: this.$route.params.id ? true : false,
        }
    },
    mounted(){
        if(this.mode == 'edit'){
            this.fetchCliente()
        }
    },
    methods:{
        fetchCliente(){
            this.loading = true
            axios.get(this.$url+'/fetch-cte-licencias/'+this.$route.params.id,{
              headers: {
                  'Content-Type' : 'application/json',
                  'Authorization': this.$token
              }
            }).then((res)=>{
                if(res.data.message){
                    this.$notify({
                    group: 'iasc',
                    title: 'Error',
                    text: res.data.message,
                    type: 'error'
                })
                }else{
                    this.cliente = res.data
                    
                }
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                    group: 'iasc',
                    title: 'Error',
                    text: err.response.data.message,
                    type: 'error'
                })
            }).finally(()=>{
                this.loading = false
            }) 

        },
        setCliente(){
            this.loading = true
            axios.post(this.$url+'/'+(this.mode == 'edit' ? 'edt-cte-licencias/'+this.$route.params.id : 'edt-cte-licencias'),this.cliente,{
              headers: {
                  'Content-Type' : 'application/json',
                  'Authorization': this.$token
              }
            }).then((res)=>{
                    if(res.data.message){
                        this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: res.data.message,
                        type: 'error'
                    })
                    }else{
                        this.$router.push('/polizas')
                    }
                }).catch((err)=>{
                    if(err.response) {
                        if(err.response.status == 500) this.logout()
                    }
                    this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    })
                }).finally(()=>{
                    this.loading = false
                }) 
        },
        
    }
}
</script>
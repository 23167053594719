<template>
  <nav class="navbar navbar-vertical navbar-expand-lg" >
    <div class="collapse navbar-collapse" id="navbarVerticalCollapse">
      <div class="navbar-vertical-content">
        <ul class="navbar-nav flex-column" id="navbarVerticalNav">
          <li class="nav-item">
            <div class="nav-item-wrapper">
              
              <div class="parent-wrapper label-1">
                <ul class="nav collapse parent show" data-bs-parent="#navbarVerticalCollapse" id="archivos">
                  <li class="collapsed-nav-item-title d-none">General</li>
                  <li class="nav-item">
                    <router-link class="nav-link label-1" :class="[$route.path == '/errores' ? 'active' : '']" to="/errores" role="button" >
                      <div class="d-flex align-items-center"><span class="nav-link-text">Errores</span></div>
                    </router-link>
                    <!-- more inner pages-->
                  </li>

                  <li class="nav-item">
                    <router-link class="nav-link label-1" :class="[$route.path == '/usuarios' ? 'active' : '']" to="/usuarios" >
                      <div class="d-flex align-items-center"><span class="nav-link-text">Usuarios</span>
                      </div>
                    </router-link>
                  </li>
                  <hr>
                  <span class="nav-link-text text-center">Control de Licencias</span>

                  <li class="nav-item">
                    <router-link class="nav-link label-1" :class="[$route.path == '/polizas' ? 'active' : '']" to="/polizas" >
                      <div class="d-flex align-items-center"><span class="nav-link-text">Pólizas</span>
                      </div>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link label-1" :class="[$route.path == '/licencias' ? 'active' : '']" to="/licencias" >
                      <div class="d-flex align-items-center"><span class="nav-link-text">Buscar Licencia</span>
                      </div>
                    </router-link>
                  </li>
                
                </ul>
              </div>
            </div>
            <!-- parent pages-->
          </li>
        </ul>
      </div>
    </div>
    <div class="navbar-vertical-footer">
      <button class="btn navbar-vertical-toggle border-0 fw-semi-bold w-100 white-space-nowrap d-flex align-items-center">
        <span class="uil uil-left-arrow-to-left fs-0"></span>
			<span class="uil uil-arrow-from-right fs-0"></span>
        <span class="navbar-vertical-footer-text ms-2">Colapsar</span>
      </button>
    </div>
  </nav>
</template>

<script>
export default {
    name: 'SideBarNav',
  }
</script>
<template>
  <nav class="navbar navbar-top navbar-expand-lg" id="navbarCombo" data-navbar-top="combo" data-move-target="#navbarVerticalNav" >
    <div class="navbar-logo">
      <button class="btn navbar-toggler navbar-toggler-humburger-icon hover-bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#navbarVerticalCollapse" aria-controls="navbarVerticalCollapse" aria-expanded="false" aria-label="Toggle Navigation"><span class="navbar-toggle-icon"><span class="toggle-line"></span></span></button>
      <a class="navbar-brand me-1 me-sm-3" href="/">
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center"><img src="@/assets/img/logo.png" alt="IASC" width="27" />
            <p class="logo-text ms-2 d-none d-sm-block">Portal IASC</p>
          </div>
        </div>
      </a>
    </div>
      
    <div class="collapse navbar-collapse navbar-top-collapse order-1 order-lg-0 justify-content-center" id="navbarTopCollapse"></div>
    <ul class="navbar-nav navbar-nav-icons flex-row">
      <li class="nav-item">
        <div class="theme-control-toggle fa-icon-wait px-2">
          <input class="form-check-input ms-0 theme-control-toggle-input" type="checkbox" data-theme-control="iascTheme" value="dark" id="themeControlToggle" />
          <label class="mb-0 theme-control-toggle-label theme-control-toggle-light" for="themeControlToggle" data-bs-toggle="tooltip" data-bs-placement="left"  title="Cambiar tema"><WeatherNightIcon /></label>
          <label class="mb-0 theme-control-toggle-label theme-control-toggle-dark" for="themeControlToggle" data-bs-toggle="tooltip" data-bs-placement="left" title="Cambiar tema"><WeatherSunnyIcon /></label>
        </div>
      </li>
      
      <li class="nav-item dropdown">
        <a class="nav-link lh-1 pe-0" id="navbarDropdownUser" href="#!" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">
          <div class="avatar avatar-l">
            <img class="rounded-circle " src="@/assets/img/logo-user.jpg" alt="IASC">
          </div>
        </a>

        <div class="dropdown-menu dropdown-menu-end navbar-dropdown-caret py-0 dropdown-profile shadow border border-300" aria-labelledby="navbarDropdownUser">
          <div class="card position-relative border-0">
            <div class="card-body p-0">
              <div class="text-center pt-4 pb-3">
                <div class="avatar avatar-l">
                  <img class="rounded-circle " src="@/assets/img/logo-user.jpg" alt="IASC">
                </div>
                <h6 class="mt-2 text-black">{{user.nombre}}</h6>
                <h6 class="mt-2 text-black"><small>{{user.email}}</small></h6>
              </div>  
            </div>

            <div class="card-footer p-0 ">
              <hr />
              <div class="px-3 pb-3">
                <a class="btn btn-iasc-secondary d-flex flex-center w-100" href="#" @click="logout()">
                  <span class="me-2" data-feather="log-out"> </span>Cerrar Sesión
                </a>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
import WeatherSunnyIcon from 'vue-material-design-icons/WeatherSunny.vue';
import WeatherNightIcon from 'vue-material-design-icons/WeatherNight.vue';
export default {
  name: 'TopBarNav',
  components:{WeatherSunnyIcon,WeatherNightIcon},

  data(){
    return{
      user: JSON.parse(Buffer.from(this.$store.state.prtiasctkn.split('.')[1], 'base64').toString()),
      logotipo: ''
    }
  },
  methods:{
    logout(){
      this.$store.dispatch('logout')
      this.$router.push({name: 'login'})
    }
  }
  }

</script>
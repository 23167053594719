<template>
   <section>
        <SideBar />
        <TopBar />
        <div class="content">   
            <div class="app-content content">
                <div class="content-overlay"></div>
                <div class="content-wrapper">
                    <div class="content-header row">
                        <div class="content-header-left col-md-8 col-12 mb-2">
                            <h3 class="content-header-title mb-0">Usuarios</h3>
                        
                        </div>
                        <div class="content-header-right text-md-right col-md-4 col-12">
                            <div class="form-group  justify-content-end d-flex">
                                <router-link to="/usuarios/nuevo-usuario" class=" btn btn-secondary btn-sm" type="button">Nuevo Usuario</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="content-body">
                        <section class="users-list-wrapper">
                            <div class="users-list-table">
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body">
                                            <div class="table">
                                                <div 
                                                        @dblclick="goto('/usuarios/modifica/'+usuario.id)" 
                                                        @click="selectedRegistro = usuario.id" 
                                                        :class="{'selected-row': (selectedRegistro == usuario.id)}"
                                                        class="row row-table border-bottom pt-1 cursor-pointer" 
                                                        v-for="usuario in usuarios" 
                                                        :key="usuario.id">
                                                    <div class="col-md-1 d-none d-md-flex">{{ usuario.id | leadingZeros(5)}}</div>
                                                    <div class="col-12 col-md-4"><span class="d-md-none">{{ usuario.id}} - </span><b>{{ usuario.name}}</b> - {{usuario.alias}}</div>
                                                    <div class="col-12 col-md-4">{{ usuario.email}})</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
   </section>
</template>

<script>
 import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
import axios from 'axios';
import LoadingSpinner from './LoadingSpinner.vue';
export default {
    name: 'UsuariosComponent',
    components:{SideBar,TopBar,LoadingSpinner},
    data(){
        return{
            usuarios: [],
            selectedRegistro:0,
            loading:false
        }
    },
    created(){
        this.getUsuarios()
    },
    methods:{
        goto(link){
            this.$router.push(link)
        },
        getUsuarios(){
            this.loading = true

            axios.get(this.$url+'/usuarios',{
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': this.$token
            }
            }).then((res)=>{
                if(res.data.message){
                    this.$notify({
                    group: 'iasc',
                    title: 'Error',
                    text: res.data.message,
                    type: 'error'
                })
                }else{
                    this.usuarios = res.data
                }
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                    group: 'iasc',
                    title: 'Error',
                    text: err.response.data.message,
                    type: 'error'
                })
            }).finally(()=>{
                this.loading = false
            })
        }
    }
}
</script>
<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">   

        </div>
    </section>
</template>

<script>
import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    name:'TableroComponente',
    components:{SideBar,TopBar}
}
</script>
import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store/index'
import jwt_decode from 'jwt-decode'

import Dashboard from '@/components/Dashboard.vue';
import LicenciasClientesForm   from '@/components/LicenciasClientesForm.vue';
import Clientes  from '@/components/LicenciasClientes.vue';
import ErroresSQL     from '@/components/ErroresSQL.vue';
import LicenciasBuscar     from '@/components/LicenciasBuscar.vue';
import LicenciasModifica     from '@/components/LicenciasModifica.vue';
import Usuarios     from '@/components/Usuarios.vue';
import UsuariosForm     from '@/components/UsuariosForm.vue';
import Login     from '@/components/LoginView.vue';
import Perfil     from '@/components/PerfilComponent.vue';

Vue.use(VueRouter)

export const routes = [
    {path: '/login',name: 'login',component: Login, meta: {requiresAuth: false}},
    {path: '/polizas',   name: 'ctesLicencias' , component: Clientes,  meta: {requiresAuth: true}},
    {path: '/polizas/modifica/:id', name: 'ctesLicenciasEdit', component: LicenciasClientesForm,  meta: {requiresAuth: true}},
    {path: '/polizas/nuevo-cliente', name: 'ctesLicenciasAdd', component: LicenciasClientesForm,  meta: {requiresAuth: true}},
    {path: '/errores', name: 'errores' , component: ErroresSQL,  meta: {requiresAuth: true}},
    {path: '/licencias', name: 'licencias', component: LicenciasBuscar,  meta: {requiresAuth: true} },
    {path: '/licencias/modifica/:id', name: 'LicenciasEdit', component: LicenciasModifica,  meta: {requiresAuth: true}},
    {path: '/usuarios',  name: 'Usuarios' , component: Usuarios,  meta: {requiresAuth: true}},
    {path: '/usuarios/nuevo-usuario',  name: 'UsuariosAdd' , component: UsuariosForm,  meta: {requiresAuth: true}},
    {path: '/usuarios/modifica/:id',  name: 'UsuariosEdit' , component: UsuariosForm,  meta: {requiresAuth: true}},
    {path: '/', name: 'Tablero', component: Dashboard,  meta: {requiresAuth: true}},
    {path: '/mi-perfil', name: 'Perfil', component: Perfil,  meta: {requiresAuth: true}},
  ]

  const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_SUBFOLDER,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      top: 0, 
      behavior: 'smooth',
    }
  }
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(item=>item.meta.requiresAuth)){
    if(!store.state.prtiasctkn){
      next({name: 'login'})
    }
    
    try{
      jwt_decode(store.state.prtiasctkn)
    }catch(error){
      next({name: 'login'})
    }
    
    if(jwt_decode(store.state.prtiasctkn).exp*1000 <= new Date().getTime()){
      next({name: 'login'})
    }
    next()
  }else{
    next()
  }
})

export default router

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    prtiasctkn: localStorage.getItem('prtiasctkn'),
  },
  getters: {
  },
  mutations: {
    setToken(state,token){
      state.prtiasctkn = token
    },
  },
  actions: {
    saveToken({commit},token){
      commit('setToken',token)
      localStorage.setItem('prtiasctkn',token)
    },
    logout({commit}){
      commit('setToken',null)
      localStorage.clear()
    }
  },
  modules: {
  }
})

<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">   
            <div class="app-content content">
                <div class="content-overlay"></div>
                <div class="content-wrapper">
                    <div class="content-header row">
                        <div class="content-header-left col-md-6 col-12 mb-2">
                            <h3 class="content-header-title mb-0">Licencias - {{ licencia.NombreCte }}</h3>
                        </div>
                        <div class="content-header-end text-md-end col-md-6 col-12">
                            <div class="form-group">
                                <router-link to="/licencias" class=" btn btn-secondary" ><i class="feather icon-arrow-left"></i> Regresar</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="content-body w-45">
                        <!-- users edit start -->
                        <section class="users-edit">
                            <div class="card">
                                <div class="card-content">
                                    <div class="card-body">
                                        <ul class="nav nav-tabs mb-2" role="tablist">
                                            <li class="nav-item">
                                                <a class="nav-link d-flex align-items-center active" id="account-tab" data-toggle="tab" href="#account" aria-controls="account" role="tab" aria-selected="true">
                                                    <i class="feather icon-user mr-25"></i><span class="d-none d-sm-block">Licencia - {{ licencia.rfc }}</span>
                                                </a>
                                            </li>
                                        </ul>
                                        <div class="tab-content">
                                            <div class="tab-pane active" id="account" aria-labelledby="account-tab" role="tabpanel">
                                                <form @submit.prevent="setLicencia()">
                                                    <div class="row">
                                                        <div class="col-12 col-md-8">
                                                            <div class="form-group">
                                                                <div class="controls">
                                                                    <label>Empresa</label>
                                                                    <input type="text" class="form-control" v-model="licencia.NombreCte" disabled>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-4">
                                                            <div class="form-group">
                                                                <div class="controls">
                                                                    <label>RFC</label>
                                                                    <input type="text" class="form-control" v-model="licencia.rfc" disabled>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-3">
                                                            <div class="form-group">
                                                                <div class="controls">
                                                                    <label>Sistema</label>
                                                                    <input type="text" class="form-control" v-model="licencia.NombreSis" disabled>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-3">
                                                            <div class="form-group">
                                                                <label>Computadora</label>
                                                                <input type="text" class="form-control" v-model="licencia.Computadora" disabled >
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-3">
                                                            <div class="form-group">
                                                                <label>Mac Address</label>
                                                                <input type="text" class="form-control" v-model="licencia.MacAddress" disabled >
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-3">
                                                            <div class="form-group">
                                                                <label>No. Serie</label>
                                                                <input type="text" class="form-control" v-model="licencia.Serial"  disabled>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-4">
                                                            <div class="form-group">
                                                                <label>Vencimiento</label>
                                                                <input type="date" class="form-control"  v-model="licencia.Vencimiento" disabled>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-4">
                                                            <div class="form-group">
                                                                <label>Ultima Comunicación</label>
                                                                <input type="text" class="form-control"  v-model="licencia.dias" disabled>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-4 ">
                                                            <div class="form-group">
                                                                <div class="controls">
                                                                    <label>Selecciona una opción</label>
                                                                    <select  class="form-control" v-model="licencia.libera" required >
                                                                        <option value="" disabled selected>Selecciona...</option>
                                                                        <option value="B">Bloquear</option>
                                                                        <option value="2">2 días</option>
                                                                        <option value="5">5 días</option>
                                                                        <option value="7">7 días</option>
                                                                        <option value="15">15 días</option>
                                                                        <option value="30">30 días</option>
                                                                        <option value="60">60 días</option>
                                                                        <option value="90">90 días</option>
                                                                        <option value="0">Liberar</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                                                            <button type="submit" class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1">
                                                            {{ mode != 'edit' ? 'Agregar Licencia' : 'Guardar Cambios' }}
                                                            </button>
                                                            <router-link to="/licencias" class="btn btn-light">Cancelar</router-link>
                                                        </div>
                                                    </div>
                                                </form>
                                                <!-- users edit account form ends -->
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <!-- users edit ends -->
                    </div>
                </div>
            </div>
        </div>
    </section>    
</template>

<script>
import axios from 'axios'
import LoadingSpinner from './LoadingSpinner.vue';
import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    name: 'LicenciasFormComponent',
    components:{SideBar,TopBar,LoadingSpinner},
    data(){
        return{
            licencia:{},
            loading:false,
            mode: this.$route.params.id ? 'edit' : 'add'
        }
    },
    created(){
        if(this.mode == 'edit'){
            this.fetchLicencia()
        }
    },
    methods:{
        fetchLicencia(){
            fetch(this.$url+'/fetch-licencia/'+this.$route.params.id, {
                method:'GET', headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$token
                }
            }).then(res=>{
                if(res.ok){
                    return res.json();
                }
            }).then((data)=>{
                this.licencia = data
            })
        },
        setLicencia(){
            axios.post(this.$url+'/'+(this.mode == 'edit' ? 'edt-licencias/'+this.$route.params.id : 'add-licencias'),{libera:this.licencia.libera,vencimiento:this.licencia.vencimiento },{
              headers: {
                  'Content-Type' : 'application/json',
                  'Authorization': this.$token
              }
            }).then((res)=>{
                console.log(res)
                    if(res.data.message){
                        this.$notify({
                            group: 'iasc',
                            title: 'Error',
                            text: res.data.message,
                            type: 'error'
                        })
                    }else{
                        this.$router.push('/licencias')
                    }
                }).catch((err)=>{
                    if(err.response) {
                        if(err.response.status == 500) this.logout()
                    }
                    this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    })
                })
        }
    }
}
</script>